import "core-js/modules/es.array.push.js";
import { getPosterList, editPoster, uploadFile, choosePoster, deletePoster } from "@/api/api";
export default {
  name: "menuList",
  data() {
    return {
      posterList: [],
      dataInfo: [],
      Info: {
        img_path: ''
      },
      dialogFormVisible: false,
      exportIds: '',
      sharePosterDiv: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getPosterList().then(res => {
        this.posterList = res.data;
      });
    },
    lkin(index) {
      console.log(index);
      this.sharePosterDiv[index] = true;
    },
    lkout(index) {
      this.sharePosterDiv[index] = false;
    },
    powerEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/admin/userPower",
        query: {
          info: data
        }
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data); //调用子组件方法
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    savePoster() {
      console.log(1);
      console.log(this.Info.img_path);
      if (this.Info.img_path) {
        editPoster(this.Info).then(res => {
          if (res.success) {
            this.getList();
          }
        });
      }
    },
    choosePoster(id) {
      choosePoster({
        id: id
      }).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
      });
    },
    deletePoster(id) {
      deletePoster({
        id: id
      }).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
      });
    },
    beforeAvatarUpload(e) {
      let formData = new FormData();
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          this.Info.img_path = res.data;
          this.savePoster();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};